import React from "react";
import Layout from "../components/reusable/Layout";
import { graphql } from "gatsby";
import Seo from "../components/reusable/Seo";
import {
  FaqsContainer,
  GetTalentSection,
  Hero,
  HuntingGlobal,
  InfoSection,
} from "../styles/pages/headhunting";
import { Briefcase } from "styled-icons/bootstrap";
import { Location } from "styled-icons/ionicons-outline";
import { Time } from "styled-icons/boxicons-regular";
import Carousel from "../components/reusable/Carousel";
import CarouselTestimonials from "../components/reusable/CarouselTestimonials";
import Faqs from "../components/reusable/Faqs";
import { useTranslation } from "react-i18next";
import { Bg, Text } from "../components/ui";
import { Button, Box } from "@mui/material";

const logoOne =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-1.png?alt=media&token=9c736c96-2da0-4366-89a6-a1d745b0921d";
const logoTwo =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-2.png?alt=media&token=3f8880c8-1b24-4840-9b9e-6ffff200ac38";
const logoThree =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-3.png?alt=media&token=d627ac40-ba19-40fa-b507-573ab8fad721";
const logoFour =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-4.png?alt=media&token=6454d413-318a-44a8-b3dd-99542c8ab241";
const logoFive =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-5.png?alt=media&token=60b8147c-7ccf-4304-a53b-572992bd6230";
const logoSix =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-6.png?alt=media&token=89855dad-92fd-4697-a9e2-985694482876";
const logoSeven =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2F3.png?alt=media&token=960fe209-b39a-460b-a553-b07bb5e6e5fe";
const logoEight =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-8.png?alt=media&token=2423ff8b-4052-40bf-a425-ec29b18e0f52";
const logoNine =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2F2.png?alt=media&token=c3cdbb9b-e9be-493d-a841-0a865f26dffd";

const headhunting =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fheadhunting%2FHeadhunting%20L.png?alt=media&token=55db94d4-0e3a-4826-ae67-a9c92c9e2a89";
const profile =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fheadhunting%2FDesde%20perfiles%20de%20tecnologi%CC%81a%20hasta%20CXO%E2%80%99s.png?alt=media&token=e7a0ffd7-f5bd-4300-8107-47daf9a216e6";
const us =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fheadhunting%2F%C2%A1Sin%20riesgos!%20Puedes%20confiar%20en%20nosotros.png?alt=media&token=be8b01a5-f41d-41a6-8282-5b206bc9a495";
const mapa =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fheadhunting%2Fmapa.png?alt=media&token=3c9ab5a9-6121-4578-96ab-cb2b6b38a08a";
const talent =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2F%C2%BFAu%CC%81n%20no%20sabes%20que%CC%81%20solucio%CC%81n%20te%20conviene%20ma%CC%81s_.png?alt=media&token=1c1ac53c-6624-4644-886a-38d1b7b16b9e";
const Headhunting = () => {
  const { t } = useTranslation(["hunting", "faqs"]);

  const faqs = [
    {
      question: t("question1"),
      answer: t("answer1"),
    },
    {
      question: t("question2"),
      answer: t("answer2"),
    },
    {
      question: t("question3"),
      answer: t("answer3"),
    },
  ];

  return (
    <Layout>
      <Seo
        title="Headhunting - Talento tech y digital en LATAM"
        canonical="https://empresas.lapieza.io/headhunting"
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fpreviews%2FLANDING%20EMPRESAS.png?alt=media&token=3dd56d07-4544-4eef-ab5c-5b53a475137e"
      />
      <Hero>
        <div className="hero-main main-landing-container-column">
          <article>
            <Box
              sx={{ maxWidth: "750px", textAlign: "center", marginTop: "30px" }}
            >
              <Text.SubTitle
                text={t("heroTitle")}
                fontSize={"23px"}
                sx={{ display: "block" }}
              />
              <Text.Title isH1 text={t("heroSubtitle")} fontSize={"38px"} />
            </Box>
            <Button
              href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza."
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                width: "270px",
                fontSize: "16px",
              }}
            >
              <Text.Body
                text={t("startWithUs")}
                sx={{
                  color: "primary.white",
                  fontSize: "16px",
                  textAlign: "start",
                }}
              />
            </Button>
          </article>
          <figure className="hero-image">
            <img src={headhunting} alt="Ilustración del equipo de LaPieza" />
          </figure>
        </div>
        <div className="hero-info">
          <div className="hero-info-container main-landing-container-row">
            <article>
              <span
                className="hero-icon-container"
                style={{
                  marginTop: "10px",
                }}
              >
                <Briefcase size={24} color="#EF8E02" />
              </span>
              <div>
                <Text.Title
                  text={t("heroItemTitle1")}
                  sx={{
                    fontSize: "32px",
                    textAlign: "start",
                  }}
                />
                <Text.Body
                  text={t("heroItemDescription1")}
                  sx={{
                    fontSize: "16px",
                    textAlign: "start",
                    display: "block",
                  }}
                />
              </div>
            </article>
            <article>
              <span
                className="hero-icon-container"
                style={{
                  marginTop: "10px",
                }}
              >
                <Location size={24} color="#EF8E02" />
              </span>
              <div>
                <Text.Title
                  text={t("heroItemTitle2")}
                  sx={{
                    fontSize: "32px",
                    textAlign: "start",
                  }}
                />
                <Text.Body
                  text={t("heroItemDescription2")}
                  sx={{
                    fontSize: "16px",
                    textAlign: "start",
                    display: "block",
                  }}
                />
              </div>
            </article>
            <article>
              <span
                className="hero-icon-container"
                style={{
                  marginTop: "10px",
                }}
              >
                <Time size={24} color="#EF8E02" />
              </span>
              <div>
                <Text.Title
                  text={t("heroItemTitle3")}
                  sx={{
                    fontSize: "32px",
                    textAlign: "start",
                  }}
                />
                <Text.Body
                  text={t("heroItemDescription3")}
                  sx={{
                    fontSize: "16px",
                    textAlign: "start",
                    display: "block",
                  }}
                />
              </div>
            </article>
          </div>
        </div>
      </Hero>
      <InfoSection>
        <div className="item-section item-section-one  main-landing-container-row">
          <Box sx={{ maxWidth: "450px" }}>
            <Text.SubTitle
              text={t("infoItemTitle1")}
              fontSize={"16px"}
              textAlign="start"
              sx={{ display: "block" }}
            />
            <Text.Title
              text={t("infoItemSubtitle1")}
              fontSize={"38px"}
              textAlign="start"
              sx={{ display: "block", marginBottom: "30px" }}
            />
            <Text.Body
              text={t("infoItemDescription1")}
              fontSize={"18px"}
              textAlign="start"
              sx={{ display: "block" }}
            />
          </Box>
          <figure>
            <img src={profile} alt="Foto de integrante de LaPieza" />
          </figure>
        </div>
        <div className="item-section item-section-two  main-landing-container-row">
          <figure>
            <img src={us} alt="Foto de integrante de LaPieza" />
          </figure>
          <Box maxWidth={"440px"}>
            <Text.Title
              text={t("infoItemSubtitle2")}
              fontSize={"38px"}
              textAlign="start"
              sx={{ display: "block", marginBottom: "30px" }}
            />
            <Text.Body
              text={t("infoItemDescription2")}
              fontSize={"18px"}
              textAlign="start"
              sx={{ display: "block", marginBottom: "20px" }}
            />
            <Text.Body
              text={t("infoItemMessage2")}
              fontSize={"18px"}
              textAlign="left"
              sx={{ display: "block", marginBottom: "20px" }}
            />
            <Button
              href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                width: "280px",
                fontSize: "16px",
                mb: 5,
              }}
            >
              <Text.Body
                text={t("requestInfo")}
                sx={{
                  color: "primary.white",
                  fontSize: "16px",
                  textAlign: "start",
                }}
              />
            </Button>
          </Box>
        </div>
        <article className="subtitle-info main-landing-container-row">
          <h3>
            <Text.Title sx={{ fontSize: "66px" }}>+500</Text.Title>
          </h3>
          <Box sx={{ maxWidth: "400px", marginBottom: "50px" }}>
            <Text.Title text={t("infoFooterTitle")} fontSize={"38px"} />
          </Box>
        </article>
        <div className="info-list">
          <Carousel logos={[logoOne, logoTwo, logoThree, logoFour, logoFive]} />
          <Carousel logos={[logoSix, logoSeven, logoEight, logoNine]} />
        </div>
      </InfoSection>
      <HuntingGlobal>
        <Bg.Linear>
          <div className="main-landing-container-column">
            <Box
              sx={{
                maxWidth: "580px",
                textAlign: "center",
                padding: "30px",
              }}
            >
              <Text.Title
                text={t("huntingTitle")}
                sx={{
                  fontSize: "38px",
                  display: "block",
                  marginTop: "60px",
                  marginBottom: "20px",
                }}
              />
              <Text.Body
                text={t("huntingDescription")}
                sx={{
                  fontSize: "20px",
                  display: "block",
                  marginBottom: "40px",
                }}
              />
            </Box>
            <Box
              sx={{
                marginBottom: "300px",
              }}
            >
              <img src={mapa} alt="Figura de dirección" />
            </Box>
          </div>
        </Bg.Linear>
        <div className="testimonials-section">
          <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
            <Text.Title text={t("testiomonialsTitle")} fontSize={"38px"} />
          </Box>
          <CarouselTestimonials />
        </div>
      </HuntingGlobal>
      <FaqsContainer>
        <Text.Title
          text={t("faqsTitle")}
          sx={{
            fontSize: "24px",
            textAlign: "start",
            paddingLeft: "85px",
          }}
        />
        <Faqs faqs={faqs} />
      </FaqsContainer>
      <GetTalentSection>
        <div className="main-landing-container-row">
          <Box
            sx={{
              maxWidth: "480px",
            }}
          >
            <Text.Title
              text={t("getTalentTitle")}
              textAlign={"start"}
              fontSize={"38px"}
              sx={{ display: "block", marginBottom: "20px" }}
            />
            <Text.Body
              text={t("getTalentDescription")}
              textAlign={"start"}
              fontSize={"18px"}
              sx={{ display: "block", marginBottom: "20px" }}
            />
            <Button
              href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                width: "230px",
                fontSize: "16px",
                mb: 5,
              }}
            >
              <Text.Body
                text={t("getTalentContactAction")}
                sx={{
                  color: "primary.white",
                  fontSize: "16px",
                  textAlign: "start",
                }}
              />
            </Button>
          </Box>
          <figure>
            <img src={talent} alt="Ilustración de LaPieza" />
          </figure>
        </div>
      </GetTalentSection>
    </Layout>
  );
};

export default Headhunting;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
